import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import {
  Contact,
  CreateUserPayload,
  Deal,
  FormService,
  SmsCode,
} from 'src/app/services/form/form.service';
import { ViewIframeService } from 'src/app/services/view-iframe/view-iframe.service';
import { COUPON_REGEX } from 'src/app/utils/coupon';
import { deleteKeysFromObject } from 'src/app/utils/delete-object-keys';

type GetUserProps = {
  email: string;
  sendSms?: boolean;
  emailAndPhoneAlreadyExist?: boolean;
};

enum ErrorCode {
  InvalidEmail = 'invalid_email',
  ConfirmEmail = 'confirm_email',
  DidYouMeanEmail = 'did_you_mean_email',
  GoToWhatsapp = 'go_to_whatsapp',
  UserAlreadyExist = 'user_already_exist',
}

function brazilianPhoneValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid =
      /^(?:(?:\+|00)?(55)\s?)?(?:(?:\(?[0-9][0-9]\)?)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/.test(
        control.value,
      );
    return valid ? null : { invalidPhone: { value: control.value } };
  };
}

declare global {
  interface Window {
    _hsq: any[];
  }
}

var _hsq = (window._hsq = window._hsq || []);

@Component({
  selector: 'app-first-form',
  templateUrl: './first-form.component.html',
  styleUrls: ['./first-form.component.scss'],
})
export class FirstFormComponent {
  @Input() urlParams: any;
  @Input() currentStep: number = 1;
  @Output() nextStep = new EventEmitter<Contact>();
  @Output() handleUserExistence = new EventEmitter<Contact>();
  @Output() deal = new EventEmitter<any>();
  @Output() contactAlreadyExist = new EventEmitter<boolean>();
  @Output() privacyPolicy = new EventEmitter<boolean>();
  @Output() phone = new EventEmitter<string>();
  @Output() emailAndPhoneAlreadyExist = new EventEmitter<boolean>();

  contact?: Contact;
  viewIsIframe: boolean = false;
  isLpLivelo: boolean = false;
  confirmEmailChecked = false;

  errorState = {
    invalidEmail: false,
    userNotFound: false,
    suggestionMessage: '',
    confirmEmailWarning: false,
  };

  attempt: number = 1;

  private destroyRef = inject(DestroyRef);

  constructor(
    private formService: FormService,
    private viewIframeService: ViewIframeService,
    private gtmService: GoogleTagManagerService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.viewIframeService.viewIsIframe$
      .pipe(takeUntilDestroyed())
      .subscribe((value) => {
        this.viewIsIframe = value;
      });
  }

  firstForm = new FormGroup({
    email: new FormControl(null, [
      Validators.required,
      Validators.pattern(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      ),
    ]),
    name: new FormControl(null, [
      Validators.required,
      Validators.pattern(/^([A-Za-zÀ-ÖØ-öø-ÿ]+)([ '-][A-Za-zÀ-ÖØ-öø-ÿ]+)+\s*$/),
    ]),
    phone: new FormControl(null, [
      Validators.required,
      brazilianPhoneValidator(),
    ]),
    checkbox: new FormControl(null, Validators.required),
  });

  get isButtonDisabled(): boolean {
    return (
      this.firstForm.invalid ||
      !this.firstForm.get('checkbox')!.value ||
      (this.errorState.confirmEmailWarning && !this.confirmEmailChecked)
    );
  }

  get isInputFieldWithError(): boolean {
    return (
      this.isFieldInvalid('email', 'pattern') ||
      this.errorState.invalidEmail ||
      !!this.errorState.suggestionMessage
    );
  }

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((params) => {
        const utm_source = params['utm_source'];
        this.isLpLivelo = utm_source === 'livelo';
      });
  }

  onSubmit() {
    const email = this.firstForm.get('email')!.value!;

    if (this.contact?.id) {
      this.updateUser();
    } else {
      this.createUser(email);
    }
  }

  getUser({
    email,
    sendSms = true,
    emailAndPhoneAlreadyExist = false,
  }: GetUserProps) {
    this.formService
      .getUser(email!, 'email')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          if (!response.contact.id) {
            return;
          }

          this.contact = response.contact;

          if (sendSms) {
            this.sendSms(response.contact.id, true, response.contact);
          } else {
            this.nextStep.next(this.contact!);
          }

          emailAndPhoneAlreadyExist &&
            this.emailAndPhoneAlreadyExist.next(true);
        },
        error: () => {
          this.errorState.userNotFound = true;
        },
      });
  }

  updateUser() {
    this.formService
      .updateUser(this.contact!, this.firstForm.get('phone')!.value!)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          if (!response || !this.contact) {
            return;
          }

          this.contact = {
            ...this.contact,
            phone: `+55${this.firstForm.get('phone')!.value!}`,
          };

          this.sendSms(
            this.contact.id!,
            this.contact?.validacao_do_numero == 'true' ? true : false,
            this.contact,
          );

          this.gtmService.pushTag({
            event: 'event-user-data',
            user_data: {
              email_address: this.contact.email, // em
              phone_number: this.contact.phone, // ph
              address: {
                first_name: this.contact.firstname, // fn
                last_name: this.contact.lastname, // ln
              },
            },
            contact_id: this.contact.id,
          });
        },
        error: (error: any) => {
          if (error.status == 403 && error.error.code == 'go_to_whatsapp') {
            this.emailAndPhoneAlreadyExist.next(true);
            this.nextStep.next({});
          } else if (error.status == 403 && error.error.code == 'same_phone') {
            this.sendSms(
              this.contact?.id!,
              this.contact?.validacao_do_numero == 'true' ? true : false,
              this.contact!,
            );
          }
        },
      });
  }

  createUser(email: string) {
    const currentUrl = window.location.href;
    const name: string = this.firstForm.get('name')!.value!;
    let contactId: any = '';
    let firstName: string = '';
    let lastName: string = '';
    this.errorState = {
      invalidEmail: false,
      userNotFound: false,
      suggestionMessage: '',
      confirmEmailWarning: false,
    };

    let arrayName = name.trim().split(' ');
    if (arrayName.length > 0) {
      firstName = arrayName[0];
      arrayName.splice(0, 1);
      lastName = arrayName.join(' ');
    } else {
      firstName = arrayName[0];
    }

    let payload: CreateUserPayload = {
      email: this.firstForm.get('email')!.value!,
      firstname: firstName!,
      phone: `+55${this.firstForm.get('phone')!.value!}`,
      lastname: lastName!,
      ...this.urlParams,
      attempt: this.attempt,
    };

    deleteKeysFromObject(['_gl', 'gtm_debug', 'vantanges'], payload);

    if (this.urlParams?.utm_content) {
      let utmContent = this.urlParams?.utm_content;
      let utmContentSplit = utmContent.split('_');

      if (
        utmContentSplit[0] == 'CID' &&
        !COUPON_REGEX.test(utmContentSplit[1])
      ) {
        contactId = utmContentSplit[1];

        payload = {
          ...payload,
          pf_calculadora__mgm___contactid_de_quem_indicou:
            this.decryptContact(contactId),
        };
      }
    }

    if (
      (payload.sfnsn || payload.hs_facebook_click_id) &&
      (currentUrl.includes('#/share/') || currentUrl.includes('%2Fshare%2F'))
    ) {
      const encryptedContact =
        currentUrl.split('#/share/')[1] || currentUrl.split('%2Fshare%2F')[1];

      if (payload.sfnsn) {
        delete payload.sfnsn;
      }

      payload = {
        ...payload,
        pf_calculadora__mgm___contactid_de_quem_indicou:
          this.decryptContact(encryptedContact),
        utm_source: 'IEG',
        utm_medium: 'legacy',
        utm_content: `CID_${encryptedContact}`,
        utm_campaign: 'IEG_LEGADO',
        utm_term: undefined,
      };
    }

    if (payload.cookies && Object.keys(payload.cookies).length !== 0) {
      payload.cookies = JSON.stringify(payload.cookies);
    } else {
      delete payload.cookies;
    }

    this.formService
      .createUser(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          if (!response) {
            return;
          }

          this.contact = {
            ...payload,
            id: response.contact_id,
          };

          this.gtmService.pushTag({
            event: 'event-user-data',
            user_data: {
              email_address: this.contact.email, // em
              phone_number: `+55${this.firstForm.get('phone')!.value!}`, // ph
              address: {
                first_name: this.contact.firstname, // fn
                last_name: this.contact.lastname, // ln
              },
            },
            contact_id: this.contact.id,
          });

          _hsq.push([
            'identify',
            {
              email: payload.email,
            },
          ]);

          _hsq.push(['trackPageView']);

          this.createDeal(this.contact.id!, this.contact);
        },
        error: ({ status, error }) => {
          this.handleCreateUserError({ status, error, email });
        },
      });
  }

  handleCreateUserError({
    status,
    error,
    email,
  }: {
    status: number;
    error: any;
    email: string;
  }) {
    if (status !== 400) return;

    switch (error.code) {
      case ErrorCode.InvalidEmail:
        this.errorState.invalidEmail = true;
        break;

      case ErrorCode.ConfirmEmail:
        this.errorState.confirmEmailWarning = true;
        break;

      case ErrorCode.DidYouMeanEmail:
        this.errorState.suggestionMessage = error.info;
        this.formatSuggestionMessage();
        break;

      case ErrorCode.GoToWhatsapp:
        this.getUser({
          email,
          sendSms: false,
          emailAndPhoneAlreadyExist: true,
        });
        break;

      case ErrorCode.UserAlreadyExist:
        this.getUser({
          email,
        });
        break;

      default:
        break;
    }

    this.attempt++;
  }

  createDeal(contactId: string, contact: Contact) {
    const payload = {
      contact_id: contactId,
      deal_name: this.firstForm.get('name')!.value!,
      ...this.urlParams,
    };

    deleteKeysFromObject(
      [
        'interClickRef',
        '_gl',
        'gtm_debug',
        'vantanges',
        'hs_facebook_click_id',
        'hs_google_click_id',
        'sfnsn',
        'cookies',
      ],
      payload,
    );

    this.formService
      .createNewDeal(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          const deal: Deal = {
            contact_id: this.contact?.contact_id,
            id: response.deal_id,
            deal_name: this.contact?.firstname,
            deal_stage: '1777303',
            pipeline: '544571',
            natureza_juridica: 'Pessoa Física',
          };

          this.deal.next(deal);
          this.sendSms(contactId, false, contact);
        },
      });
  }

  sendSms(contactId: string, contactAlreadyExist: boolean, contact: Contact) {
    let payload: SmsCode = {
      contact_id: contactId,
      resend: false,
      phone: `+55${this.firstForm.get('phone')!.value!}`,
    };

    this.formService
      .sendSmsCode(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.contact = contact;
          this.phone.next(this.firstForm.get('phone')!.value!);
          this.emailAndPhoneAlreadyExist.next(false);
          this.contactAlreadyExist.next(contactAlreadyExist);
          this.nextStep.next(contact);
        },
      });
  }

  decryptContact(contact_id: string) {
    const partnershipCode = parseInt(contact_id.substring(0, 2), 16);
    const encryptedCodeId = parseInt(
      contact_id.substring(2, contact_id.length),
      16,
    );
    const number = encryptedCodeId - partnershipCode - 452;

    return number.toString();
  }

  openPrivacyPolicy() {
    this.privacyPolicy.next(true);
  }

  isFieldInvalid(controlName: string, errorType: string): boolean {
    const control = this.firstForm.get(controlName);

    return control
      ? control.invalid && control.dirty && control.hasError(errorType)
      : false;
  }

  formatSuggestionMessage() {
    const match = this.errorState.suggestionMessage.match(/@\S+(?=\?)/);

    if (!match) {
      return;
    }

    const suggestion = match[0];

    this.errorState.suggestionMessage =
      this.errorState.suggestionMessage.replace(
        suggestion,
        `<b>${suggestion}</b>`,
      );
  }

  onConfirmEmailChange(event: MatSlideToggleChange): void {
    this.confirmEmailChecked = event.checked;
  }
}
