import { Component, Input } from '@angular/core';

type Flag = 'in_progress'  | 'stopped' | 'invalid'

type Partner = {
  condition: string;
  offer?: string;
  flag: Flag;
  regulament?: string;
}

type PartnersText = {
  [partner: string]: Partner,
};

export const basePartnersData: PartnersText = {
  'afas': {
    condition: 'Até 15% de desconto no consumo da conta de luz Bulbe.',
    flag: 'in_progress',
  },
  'inter': {
    condition: 'Até 15% de desconto no consumo da conta de luz Bulbe.',
    offer: '77% de cashback na sua primeira conta de luz Bulbe.',
    flag: 'in_progress',
  },
  'livelo': {
    condition: 'Até 15% de desconto no consumo da conta de luz Bulbe.',
    offer: '12 pontos Livelo a cada R$1 da primeira fatura Bulbe',
    flag: 'in_progress',
  },
  'default': {
    condition: 'Até 15% de desconto no consumo da conta de luz Bulbe.',
    flag: 'in_progress',
  },
  'verace': {
    condition: 'verace',
    flag: 'invalid',
  },
};

const partnerAliases: Record<string, string> = {
  'cefs': 'afas',
  'edmil': 'afas',
  'gerdau': 'afas',
  'csenergy': 'afas',
  'direcional': 'afas',
  'farmaciadavaleria': 'afas',
  'semlimite': 'afas',
  'semlimitecolab': 'afas',
}

export const partnersText: PartnersText = Object.keys(partnerAliases).reduce(
  (acc, alias) => {
    const baseKey = partnerAliases[alias];

    if (basePartnersData[baseKey]) {
      acc[alias] = { ...basePartnersData[baseKey] };
    }

    return acc;
  },
  { ...basePartnersData }
)

@Component({
  selector: 'app-custom-policy',
  templateUrl: './custom-policy.component.html',
  styleUrls: ['./custom-policy.component.scss']
})
export class CustomPolicyComponent {
  @Input() utmSource: string | undefined = "default";

  partner = partnersText['default'];

  ngOnInit() {
    this.partner = this.getPartner();
  }

  private getBasePartner(utmSource: string): string {
    const matchingPartner = Object.keys(partnersText).find(partnerKey =>
      utmSource.startsWith(partnerKey)
    )

    return matchingPartner || 'default'
  }

  getPartner() {
    const basePartner = this.utmSource ? this.getBasePartner(this.utmSource) : 'default';
    const partnerData = partnersText[basePartner];

    return partnerData.flag === 'in_progress' ? partnerData : partnersText['default'];
  }
}
