import { Component } from '@angular/core';
import { ViewIframeService } from './services/view-iframe/view-iframe.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  viewIsIframe: boolean = false;

  constructor(private viewIframeService: ViewIframeService) {
    this.viewIframeService.checkIsIframe();

    this.viewIframeService.viewIsIframe$.subscribe((value) => {
      this.viewIsIframe = value;
    });
  }
}
