import { Component, Input, ViewChild } from '@angular/core';
import { Contact } from 'src/app/services/form/form.service';
import { VideoSectionComponent } from './video-section/video-section.component';
import { ViewIframeService } from 'src/app/services/view-iframe/view-iframe.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-fifth-form',
  templateUrl: './fifth-form.component.html',
  styleUrls: ['./fifth-form.component.scss'],
})
export class FifthFormComponent {
  @ViewChild(VideoSectionComponent)
  videoSectionComponent!: VideoSectionComponent;

  @Input() contact!: Contact;

  viewIsIframe: boolean = false;
  referralCoupon: string | null = null;
  whatsappApiText =
    'https://api.whatsapp.com/send?text=*Sabia%20que%20voc%C3%AA%20pode%20economizar%20at%C3%A9%2015%%20no%20consumo%20da%20sua%20conta%20de%20luz?%20%E2%9A%A1%20A%20Bulbe%20%C3%A9%20a%20solu%C3%A7%C3%A3o%20perfeita%20para%20quem%20busca%20economia,%20sem%20obras%20ou%20burocracia.*%0A%0AAcesse%20o%20link%20e%20garanta%20essa%20oferta.%0A%0Ahttps%3A%2F%2Fassine.bulbeenergia.com.br';

  constructor(
    private viewIframeService: ViewIframeService,
  ) {
    this.viewIframeService.viewIsIframe$
      .pipe(takeUntilDestroyed())
      .subscribe((value) => {
        this.viewIsIframe = value;
      });
  }

  ngOnInit(): void {
    this.loadReferralCoupon();
  }

  loadReferralCoupon(): void {
    const { id } = this.contact;

    let newId = parseInt(id!);

    newId = newId + 16 + 452;
    this.referralCoupon = `10${newId.toString(16)}`;
  }

  openWppLink() {
    window.open(
      this.referralCoupon
        ? `${this.whatsappApiText}%2F%3Fcupom%3D${this.referralCoupon}`
        : this.whatsappApiText,
    );
  }
}
