<div>
  <app-coupon-alert iconName="energy" title="Energia Bulbe:"
    [message]="partner.condition">
  </app-coupon-alert>
  <app-coupon-alert *ngIf="!!partner.offer" iconName="percentage" title="Oferta:"
    [message]="partner.offer">
  </app-coupon-alert>

  <a *ngIf="!!partner.regulament" [href]="partner.regulament" target="_blank">Regualmento</a>
</div>
