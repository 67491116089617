import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface Contact {
  email?: string;
  phone?: string;
  lastname?: string;
  firstname?: string;
  id?: string;
  contact_id?: string;
  aceite_do_termo_de_adesao?: string;
  codigo_criptografado?: string;
  validacao_do_numero?: string;
  pf_calculadora__mgm___contactid_de_quem_indicou?: string;
  deal?: Deal;

  cpf?: string | null;

  _gl?: string;
  gtm_debug?: any;
  vantagens?: any;
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
  hs_facebook_click_id?: string;
  hs_google_click_id?: string;
  interClickRef?: string;
  sfnsn?: string;
  cookies?: {};
}

export interface SmsCode {
  contact_id: string;
  resend: boolean;
  phone?: string;
}

export interface ValidateSms {
  contact_id: string;
  code: string;
}

export interface MultipleInstallations {
  contact_id: string;
}

export interface Deal {
  contact_id?: string;
  id?: string;
  deal_id?: string;
  cpf?: string | null;
  cnpj?: string | null;
  deal_name?: string;
  natureza_juridica?: string;
  deal_stage?: string;
  pipeline?: string;
  interClickRef?: string;

  _gl?: string;
  gtm_debug?: any;
  vantagens?: any;
  utm_source?: string;
  utm_content?: string;
};

export type CreateUserPayload = {
  attempt?: number
} & Contact;

export type ReferralCouponResponse = {
  clienteId: string | null;
  clienteNome: string | null;
  validado: boolean;
};

export type AcceptContractPayload = {
  contact_id: string;
  cupom_indicacao?: string;
  utm_indique_ganhe?: boolean;
  app?: boolean;
};

export type GetReferralCouponByHubspotIdResponse = {
  cupomIndicacao: string;
};

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private http: HttpClient) {}

  createUser(payload: Contact) {
    return this.http.post(`${environment.api}/v2/create-contact`, payload);
  }

  updateUser(payload: Contact, phone: string) {
    return this.http.patch(`${environment.api}/v2/update-contact`, {
      contact_id: payload.id,
      phone: `+55${phone}`,
    });
  }

  getUser(payload: string, type: string) {
    let params;
    if (type === 'contact_id') {
      params = {
        contact_id: payload,
      };
    } else if (type === 'email') {
      params = {
        email: payload,
      };
    }
    return this.http.get(`${environment.api}/get-contact-info`, {
      params,
    });
  }

  accContract(payload: AcceptContractPayload) {
    return this.http.patch(`${environment.api}/accept-contract`, payload);
  }

  getAllDeals(contactId: string) {
    return this.http.get(`${environment.api}/get-all-contact-deals`, {
      params: {
        contact_id: contactId,
      },
    });
  }

  getDeal(dealId: string) {
    return this.http.get(`${environment.api}/get-deal`, {
      params: {
        deal_id: dealId,
      },
    });
  }

  createNewDeal(payload: Deal) {
    return this.http.post(`${environment.api}/v2/create-deal`, payload);
  }

  updateDeal(payload: Deal) {
    return this.http.patch(`${environment.api}/v2/update-deal`, payload);
  }

  sendSmsCode(payload: SmsCode) {
    return this.http.patch(`${environment.api}/v2/generate-code-sms`, payload);
  }

  validateSmsCode(payload: ValidateSms) {
    return this.http.patch(`${environment.api}/v2/validate-code-sms `, payload);
  }

  getAllContactInstallations(payload: MultipleInstallations) {
    return this.http.get(`${environment.api}/get-all-contact-installations`, {
      params: {
        contact_id: payload.contact_id,
      },
    });
  }

  updateDeals(payload: MultipleInstallations) {
    return this.http.patch(
      `${environment.api}/connect-or-disconnect-installation`,
      { installations: payload },
    );
  }

  validateCemigLogin(contactId: string) {
    return this.http.get(`${environment.api}/validate-cemig-login`, {
      params: {
        contact_id: contactId,
      },
    });
  }

  updateContactCpf(payload: Contact) {
    return this.http.patch(`${environment.api}/v2/update-contact-cpf`, payload);
  }

  extractCookies(params: Contact) {
    const cookies: { [key: string]: string } = {};

    for (const [key, value] of Object.entries(params)) {
      if (key.startsWith('ck-')) {
        const cookieKey = key.slice(3);
        cookies[cookieKey] = value;
      }
    }

    const cookieString = document.cookie;

    if (cookieString) {
      cookieString.split('; ').forEach((cookie) => {
        const [key, value] = cookie.split('=');

        if (!cookies[key]) {
          cookies[key] = decodeURIComponent(value);
        }
      });
    }

    return cookies;
  }

  validateReferralCoupon(referralCoupon: string) {
    return this.http.get(
      `${environment.api_erp_qa}/IndiqueGanheErp/ValidarCupomIndicacao/${referralCoupon}`,
      {
        params: {
          key: 'Adesão@Bulbe',
        },
      },
    ) as Observable<ReferralCouponResponse>;
  }

  getReferralCouponByHubspotId(hubspotId: number) {
    return this.http.get(
      `${environment.api_erp_qa}/IndiqueGanheErp/ObterCupomIndicacaoByHubspotId/${hubspotId}`,
    ) as Observable<GetReferralCouponByHubspotIdResponse>;
  }
}
