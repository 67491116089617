<div class="card" [ngClass]="{'iframe': viewIsIframe}">
  <div class="wrapper-form">
    <app-title [currentStep]="1" title="Informe seus dados."
      subtitle="Preencha seus dados e comece a economizar na sua conta de luz."></app-title>

    <form [formGroup]="firstForm" class="firstForm">
      <div class="content-form-inputs">
        <div class="group-form">
          <label for="name" class="form-label">Nome completo <span class="prop-required">*</span></label>
          <input type="name" maxlength="50" class="input lg" id="name" formControlName="name"
            placeholder="Seu nome e sobrenome" />

          <p *ngIf="isFieldInvalid('name', 'pattern')" class="error-message sm">
            Formato inválido.
          </p>
        </div>

        <div class="group-form">
          <label for="phone" class="form-label">Número de celular <span class="prop-required">*</span> </label>
          <input type="phone" maxlength="15" class="input lg" id="phone" formControlName="phone"
            placeholder="(00) 90000-0000" mask="(00) 00000-0000" />
          <p *ngIf="isFieldInvalid('phone', 'invalidPhone')" class="error-message sm">
            O número de telefone celular não é válido.
          </p>
        </div>

        <div class="group-form">
          <label for="email" class="form-label">E-mail <span class="prop-required">*</span> </label>
          <input class="input lg" [ngClass]="{'error': isInputFieldWithError }" type="email" maxlength="50" id="email"
            formControlName="email" placeholder="nome@email.com" />


          <p *ngIf="urlParams?.vantagens" class="email-alert">
            O e-mail deve ser o mesmo cadastrado no Clube de Benefícios.
          </p>

          <ng-container *ngIf="errorState">
            <p *ngIf="isFieldInvalid('email', 'pattern') || errorState.invalidEmail" class="error-message sm">
              O e-mail fornecido não é válido.
            </p>

            <p *ngIf="errorState.userNotFound" class="error-message sm">
              Não encontramos um contato com este email.
            </p>

            <p *ngIf="!!errorState.suggestionMessage" class="error-message sm"
              [innerHTML]="errorState.suggestionMessage"></p>
          </ng-container>

        </div>
      </div>

      <div class="group-form checkbox">
        <mat-slide-toggle formControlName="checkbox" id="slide-toggle">
          Li e concordo com a&nbsp;
        </mat-slide-toggle>
        <a href="https://8488319.fs1.hubspotusercontent-na1.net/hubfs/8488319/Termos%20Bulbe%20Energia/Pol%C3%ADtica%20de%20Privacidade%20Bulbe%20Energia%2007.02.2024.pdf"
          target="_alt">
          <span>Política de Privacidade.</span>
        </a>
      </div>

      <div *ngIf="errorState.confirmEmailWarning" class="confirm-wrapper checkbox">
        <mat-slide-toggle [checked]="confirmEmailChecked" (change)="onConfirmEmailChange($event)"
          id="confirm-email-slide-toggle-mobile">
          Confirme seu endereço de e-mail.
        </mat-slide-toggle>
      </div>
    </form>
  </div>

  <div>
    <mat-divider color="#C2CCD6"></mat-divider>

    <div class="wrapper-btn" [ngClass]="{'confirm': errorState.confirmEmailWarning}">
      <mat-slide-toggle *ngIf="errorState.confirmEmailWarning" id="confirm-email-slide-toggle-desktop"
        [checked]="confirmEmailChecked" (change)="onConfirmEmailChange($event)">
        Confirme seu endereço de e-mail.
      </mat-slide-toggle>
      <button class="btn bulbe lg no-responsive-icon" [disabled]="isButtonDisabled" (click)="onSubmit()">
        <span *ngIf="viewIsIframe">Economizar agora</span>
        <span *ngIf="!viewIsIframe">Próximo</span>
        <app-icons [showInResponsiveScreen]="false" name="arrow-right"
          [color]="isButtonDisabled ? '#C2CCD6;' : '#08068D'"></app-icons>
      </button>
    </div>
  </div>
</div>
